import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import clientConfig from "../data/ClientConfig";
import Moment from "moment";
import DOMPurify from "dompurify"; // Import DOMPurify
import '../artikel.css';

const Single = () => {
  const { id } = useParams();
  const [post, setPost] = useState({});

  useEffect(() => {
    let url = `${clientConfig.siteUrl}/posts/${id}`;
    axios
      .get(url)
      .then((res) => {
        console.log("res", res);
        setPost(res.data);
      })
      .catch((err) => {
        console.log("error:", err.message);
      });
  }, [id]);

  return (
    <>
      <section className="bg-[#BBD6B8] p-10 min-h-screen">
        <div className="sm:text-center pb-8 pl-4 "></div>
        <div className="max-w-5xl mx-auto">
          {Object.keys(post).length ? (
            <div className="p-5 article-container">
              {/* featured image */}
              {/* <div className="max-w-md">
                <img className="w-full" src={post.featured_src} alt="blog" />
              </div> */}

              {/* Judul Artikel */}
              <h1 className="text-xl sm:text-4xl font-bold">
                {post.title.rendered}
              </h1>
              <p className="text-sm dark:text-gray-600">
                {Moment(post.date).format("LL")}
              </p>

              {/* Isi Artikel dengan DOMPurify */}
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(post.content.rendered),
                }}
              />
            </div>
          ) : (
            "Loading...."
          )}
        </div>
      </section>
    </>
  );
};
export default Single;
