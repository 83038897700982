import React from 'react';
import ReactDOM from 'react-dom';

//import { BrowserRouter } from 'react-router-dom'
import './index.css';
import App from './App';
import ReactGA from "react-ga4";


ReactGA.initialize("G-406J6FC2G9");

ReactGA.send({ hitType: "pageview", page: window.location.pathname, });
//ReactGA.pageview(window.location.pathname + window.location.search); 

ReactDOM.render(
  <React.StrictMode>

    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


