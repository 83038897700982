import drtony1 from '../assets/images/others/drtony1.jpeg'
import drtony2 from '../assets/images/others/drtony2.jpeg'
import drtony3 from '../assets/images/others/drtony3.jpeg'
import drtony4 from '../assets/images/others/drtony4.jpeg'
import drtony5 from '../assets/images/others/drtony5.jpeg'
import drtony6 from '../assets/images/others/drtony6.jpeg'
import drtony7 from '../assets/images/others/drtony7.jpeg'
import drtony8 from '../assets/images/others/drtony8.jpeg'
import drtony9 from '../assets/images/others/drtony9.jpeg'
import drtony10 from '../assets/images/others/drtony10.jpeg'
import drtony11 from '../assets/images/others/drtony11.jpeg'
import drtony12 from '../assets/images/others/drtony12.jpeg'
import drtony13 from '../assets/images/others/drtony13.jpeg'
import drtony14 from '../assets/images/others/drtony14.jpeg'
import drtony15 from '../assets/images/others/drtony15.jpeg'
import drtony16 from '../assets/images/others/drtony16.jpeg'
import drtony17 from '../assets/images/others/drtony17.jpeg'
import drtony18 from '../assets/images/others/drtony18.jpeg'
import drtony19 from '../assets/images/others/drtony19.jpeg'
import drtony20 from '../assets/images/others/drtony20.jpeg'
import drtony21 from '../assets/images/others/drtony21.jpeg'
import drtony22 from '../assets/images/others/drtony22.jpeg'
import drtony23 from '../assets/images/others/drtony23.jpeg'
import drtony24 from '../assets/images/others/drtony24.jpeg'
import drtony25 from '../assets/images/others/drtony25.jpeg'
import drtony26 from '../assets/images/others/drtony26.jpeg'
import drtony27 from '../assets/images/others/drtony27.jpeg'
import drtony28 from '../assets/images/others/drtony28.jpeg'
import drtony29 from '../assets/images/others/drtony29.jpeg'
import drtony30 from '../assets/images/others/drtony30.jpeg'
import drtony31 from '../assets/images/others/drtony31.jpeg'
import drtony32 from '../assets/images/others/drtony32.jpeg'
import drtony33 from '../assets/images/others/drtony33.jpeg'
import drtony34 from '../assets/images/others/drtony34.jpeg'
import drtony35 from '../assets/images/others/drtony35.jpeg'
import drtony36 from '../assets/images/others/drtony36.jpeg'
import drtony37 from '../assets/images/others/drtony37.jpeg'
import drtony38 from '../assets/images/others/drtony38.jpeg'
import drtony39 from '../assets/images/others/drtony39.jpeg'
import drtony40 from '../assets/images/others/drtony40.jpeg'
import drtony41 from '../assets/images/others/drtony41.jpeg'
import drtony42 from '../assets/images/others/drtony42.jpeg'
import drtony43 from '../assets/images/others/drtony43.jpeg'
import drtony44 from '../assets/images/others/drtony44.jpeg'
import drtony45 from '../assets/images/others/drtony45.jpeg'
import drtony46 from '../assets/images/others/drtony46.jpeg'
import drtony47 from '../assets/images/others/drtony47.jpeg'
import ferrari1 from '../assets/images/others/ferrari1.jpeg'
import ferrari2 from '../assets/images/others/ferrari2.jpeg'
import ferrari3 from '../assets/images/others/ferrari3.jpeg'
import mentri1 from '../assets/images/mentri/mentri1.jpeg'
import mentri2 from '../assets/images/mentri/mentri2.jpeg'
import mentri3 from '../assets/images/mentri/mentri3.jpeg'
import mentri4 from '../assets/images/mentri/mentri4.jpeg'
import mentri5 from '../assets/images/mentri/mentri5.jpeg'
import mentri6 from '../assets/images/mentri/mentri6.jpeg'
import mentri7 from '../assets/images/mentri/mentri7.jpeg'
import mentri8 from '../assets/images/mentri/mentri8.jpeg'
import mentri9 from '../assets/images/mentri/mentri9.jpeg'
import mentri10 from '../assets/images/mentri/mentri10.jpeg'
import mentri11 from '../assets/images/mentri/mentri11.jpeg'
import mentri12 from '../assets/images/mentri/mentri12.jpeg'
import mentri13 from '../assets/images/mentri/mentri13.jpeg'
import mentri14 from '../assets/images/mentri/mentri14.jpeg'
import menkes1 from '../assets/images/menkes/menkes1.jpeg'
import menkes2 from '../assets/images/menkes/menkes2.jpeg'
import menkes3 from '../assets/images/menkes/menkes3.jpeg'
import menkes4 from '../assets/images/menkes/menkes4.jpeg'
import menkes5 from '../assets/images/menkes/menkes5.jpeg'
import menkes6 from '../assets/images/menkes/menkes6.jpeg'
import menkes7 from '../assets/images/menkes/menkes7.jpeg'
import menkes8 from '../assets/images/menkes/menkes8.jpeg'
import menkes9 from '../assets/images/menkes/menkes9.jpeg'
import menkes10 from '../assets/images/menkes/menkes10.jpeg'
import menkes11 from '../assets/images/menkes/menkes11.jpeg'
import germany1 from '../assets/images/germany/germany1.jpeg'
import germany2 from '../assets/images/germany/germany2.jpeg'
import germany3 from '../assets/images/germany/germany3.jpeg'
import germany4 from '../assets/images/germany/germany4.jpeg'
import germany5 from '../assets/images/germany/germany5.jpeg'


import serti1 from '../assets/images/certificate/serti1.jpeg'
import serti2 from '../assets/images/certificate/serti2.jpeg'
import serti3 from '../assets/images/certificate/serti3.jpeg'
import serti4 from '../assets/images/certificate/serti4.jpeg'
import serti5 from '../assets/images/certificate/serti5.jpeg'
import serti6 from '../assets/images/certificate/serti6.jpeg'
import serti7 from '../assets/images/certificate/serti7.jpeg'
import serti8 from '../assets/images/certificate/serti8.jpeg'
import serti9 from '../assets/images/certificate/serti9.jpeg'
import serti10 from '../assets/images/certificate/serti10.jpeg'
import serti11 from '../assets/images/certificate/serti11.jpeg'
import serti12 from '../assets/images/certificate/serti12.jpeg'
import serti13 from '../assets/images/certificate/serti13.jpeg'
import serti14 from '../assets/images/certificate/serti14.jpeg'
import serti15 from '../assets/images/certificate/serti15.jpeg'
import serti16 from '../assets/images/certificate/serti16.jpeg'
import serti17 from '../assets/images/certificate/serti17.jpeg'
import serti18 from '../assets/images/certificate/serti18.jpeg'
import serti19 from '../assets/images/certificate/serti19.jpeg'
import serti20 from '../assets/images/certificate/serti20.jpeg'
import serti21 from '../assets/images/certificate/serti21.jpeg'
import serti22 from '../assets/images/certificate/serti22.jpeg'
import serti23 from '../assets/images/certificate/serti23.jpeg'
import articleno from '../assets/images/artikel/articlenotfound.png'

//update image 17 feb 25
import group1 from '../assets/images/random17feb25/group1.jpeg'
import group2 from '../assets/images/random17feb25/group2.jpeg'
import group3 from '../assets/images/random17feb25/group3.jpeg'
import group4 from '../assets/images/random17feb25/group4.jpeg'
import group5 from '../assets/images/random17feb25/group5.jpeg'
import group6 from '../assets/images/random17feb25/group6.jpeg'
import group7 from '../assets/images/random17feb25/group7.jpeg'
import group8 from '../assets/images/random17feb25/group8.jpeg'
import group9 from '../assets/images/random17feb25/group9.jpeg'

import koran from '../assets/images/random17feb25/koran.jpeg'

import projek1 from '../assets/images/random17feb25/projek1.jpeg'
import projek2 from '../assets/images/random17feb25/projek2.jpeg'

import selfi1 from '../assets/images/random17feb25/selfi1.jpeg'
import selfi2 from '../assets/images/random17feb25/selfi2.jpeg'
import selfi3 from '../assets/images/random17feb25/selfi3.jpeg'
import selfi4 from '../assets/images/random17feb25/selfi4.jpeg'
import selfi5 from '../assets/images/random17feb25/selfi5.jpeg'
import selfi6 from '../assets/images/random17feb25/selfi6.jpeg'
import selfi7 from '../assets/images/random17feb25/selfi7.jpeg'
import selfi8 from '../assets/images/random17feb25/selfi8.jpeg'
import selfi9 from '../assets/images/random17feb25/selfi9.jpeg'

import shot1 from '../assets/images/random17feb25/shot1.jpeg'
import shot2 from '../assets/images/random17feb25/shot2.jpeg'
import shot3 from '../assets/images/random17feb25/shot3.jpeg'
import shot4 from '../assets/images/random17feb25/shot4.jpeg'
import shot5 from '../assets/images/random17feb25/shot5.jpeg'
import shot6 from '../assets/images/random17feb25/shot6.jpeg'
import shot7 from '../assets/images/random17feb25/shot7.jpeg'

export const artikelnofound = [{
    id: 1,
    imgSrc: articleno,
},

]


export const serti = [{
    id: 1,
    imgSrc: serti1,
},
{
    id: 2,
    imgSrc: serti2,
},
{
    id: 3,
    imgSrc: serti3,
},
{
    id: 4,
    imgSrc: serti4,
},
{
    id: 5,
    imgSrc: serti5,
},
{
    id: 6,
    imgSrc: serti6,
},
{
    id: 7,
    imgSrc: serti7,
},
{
    id: 8,
    imgSrc: serti8,
},
{
    id: 9,
    imgSrc: serti9,
},
{
    id: 10,
    imgSrc: serti10,
},
{
    id: 11,
    imgSrc: serti11,
},
{
    id: 12,
    imgSrc: serti12,
},
{
    id: 13,
    imgSrc: serti13,
},
{
    id: 14,
    imgSrc: serti14,
},
{
    id: 15,
    imgSrc: serti15,
},
{
    id: 16,
    imgSrc: serti16,
},
{
    id: 17,
    imgSrc: serti17,
},
{
    id: 18,
    imgSrc: serti18,
},
{
    id: 19,
    imgSrc: serti19,
},
{
    id: 20,
    imgSrc: serti20,
},
{
    id: 21,
    imgSrc: serti21,
},
{
    id: 22,
    imgSrc: serti22,
},
{
    id: 23,
    imgSrc: serti23,
},
]

export const gallery = [
    {
        id: 1,
        imgSrc: drtony1,
    },
    {
        id: 2,
        imgSrc: drtony2,
    },
    {
        id: 3,
        imgSrc: drtony3,
    },
    {
        id: 4,
        imgSrc: drtony4,
    },
    {
        id: 5,
        imgSrc: drtony5,
    },
    {
        id: 6,
        imgSrc: drtony6,
    },
    {
        id: 7,
        imgSrc: drtony7,
    },
    {
        id: 8,
        imgSrc: drtony8,
    },
    {
        id: 9,
        imgSrc: drtony9,
    },
    {
        id: 10,
        imgSrc: drtony10,
    },
    {
        id: 11,
        imgSrc: drtony11,
    },
    {
        id: 12,
        imgSrc: drtony12,
    },
    {
        id: 13,
        imgSrc: drtony13,
    },
    {
        id: 14,
        imgSrc: drtony14,
    },
    {
        id: 15,
        imgSrc: drtony15,
    },
    {
        id: 16,
        imgSrc: drtony16,
    },
    {
        id: 17,
        imgSrc: drtony17,
    },
    {
        id: 18,
        imgSrc: drtony18,
    },
    {
        id: 19,
        imgSrc: drtony19,
    },
    {
        id: 20,
        imgSrc: drtony20,
    },
    {
        id: 21,
        imgSrc: drtony21,
    },
    {
        id: 22,
        imgSrc: drtony22,
    },
    {
        id: 23,
        imgSrc: drtony23,
    },
    {
        id: 24,
        imgSrc: drtony24,
    },
    {
        id: 25,
        imgSrc: drtony25,
    },
    {
        id: 26,
        imgSrc: drtony26,
    },
    {
        id: 27,
        imgSrc: drtony27,
    },
    {
        id: 28,
        imgSrc: drtony28,
    },
    {
        id: 29,
        imgSrc: drtony29,
    },
    {
        id: 30,
        imgSrc: drtony30,
    },
    {
        id: 31,
        imgSrc: drtony31,
    },
    {
        id: 32,
        imgSrc: drtony32,
    },
    {
        id: 33,
        imgSrc: drtony33,
    },
    {
        id: 34,
        imgSrc: drtony34,
    },
    {
        id: 35,
        imgSrc: drtony35,
    },
    {
        id: 36,
        imgSrc: drtony36,
    },
    {
        id: 37,
        imgSrc: drtony37,
    },
    {
        id: 38,
        imgSrc: drtony38,
    },
    {
        id: 39,
        imgSrc: drtony39,
    },
    {
        id: 40,
        imgSrc: drtony40,
    },
    {
        id: 41,
        imgSrc: drtony41,
    },
    {
        id: 42,
        imgSrc: drtony42,
    },
    {
        id: 43,
        imgSrc: drtony43,
    },
    {
        id: 44,
        imgSrc: drtony44,
    },
    {
        id: 45,
        imgSrc: drtony45,
    },
    {
        id: 46,
        imgSrc: drtony46,
    },
    {
        id: 47,
        imgSrc: drtony47,
    },
    {
        id: 48,
        imgSrc: ferrari1,
    },
    {
        id: 49,
        imgSrc: ferrari2,
    },
    {
        id: 50,
        imgSrc: ferrari3,
    },

    {
        id: 51,
        imgSrc: mentri1,
    },
    {
        id: 52,
        imgSrc: mentri2,
    },
    {
        id: 53,
        imgSrc: mentri3,
    },
    {
        id: 54,
        imgSrc: mentri4,
    },
    {
        id: 55,
        imgSrc: mentri5,
    },
    {
        id: 56,
        imgSrc: mentri6,
    },
    {
        id: 57,
        imgSrc: mentri7,
    },
    {
        id: 58,
        imgSrc: mentri8,
    },
    {
        id: 59,
        imgSrc: mentri9,
    },
    {
        id: 60,
        imgSrc: mentri10,
    },
    {
        id: 61,
        imgSrc: mentri11,
    },
    {
        id: 62,
        imgSrc: mentri12,
    },
    {
        id: 63,
        imgSrc: mentri13,
    },
    {
        id: 64,
        imgSrc: mentri14,
    },
    {
        id: 65,
        imgSrc: menkes1,
    },
    {
        id: 66,
        imgSrc: menkes2,
    },
    {
        id: 67,
        imgSrc: menkes3,
    },
    {
        id: 68,
        imgSrc: menkes4,
    },
    {
        id: 69,
        imgSrc: menkes5,
    },
    {
        id: 70,
        imgSrc: menkes6,
    },
    {
        id: 71,
        imgSrc: menkes7,
    },
    {
        id: 72,
        imgSrc: menkes8,
    },
    {
        id: 73,
        imgSrc: menkes9,
    },
    {
        id: 74,
        imgSrc: menkes10,
    },
    {
        id: 75,
        imgSrc: menkes11,
    },
    {
        id: 76,
        imgSrc: germany1,
    },
    {
        id: 77,
        imgSrc: germany2,
    },
    {
        id: 78,
        imgSrc: germany3,
    },
    {
        id: 79,
        imgSrc: germany4,
    },
    {
        id: 80,
        imgSrc: germany5,
    },
    {
        id: 81,
        imgSrc: group1,
    },
    {
        id: 82,
        imgSrc: group2,
    },
    {
        id: 83,
        imgSrc: group3,
    },
    {
        id: 84,
        imgSrc: group4,
    },
    {
        id: 85,
        imgSrc: group5,
    },
    {
        id: 86,
        imgSrc: group6,
    },
    {
        id: 87,
        imgSrc: group7,
    },
    {
        id: 88,
        imgSrc: group8,
    },
    {
        id: 89,
        imgSrc: group9,
    },
    {
        id: 90,
        imgSrc: koran,
    },
    {
        id: 91,
        imgSrc: projek1,
    },
    {
        id: 92,
        imgSrc: projek2,
    },
    {
        id: 93,
        imgSrc: selfi1,
    },
    {
        id: 94,
        imgSrc: selfi2,
    },
    {
        id: 95,
        imgSrc: selfi3,
    },
    {
        id: 96,
        imgSrc: selfi4,
    },
    {
        id: 97,
        imgSrc: selfi5,
    },
    {
        id: 98,
        imgSrc: selfi6,
    },
    {
        id: 99,
        imgSrc: selfi7,
    },
    {
        id: 100,
        imgSrc: selfi8,
    },
    {
        id: 101,
        imgSrc: selfi9,
    },
    {
        id: 102,
        imgSrc: shot1,
    },
    {
        id: 103,
        imgSrc: shot2,
    },
    {
        id: 104,
        imgSrc: shot3,
    },
    {
        id: 105,
        imgSrc: shot4,
    },
    {
        id: 106,
        imgSrc: shot5,
    },
    {
        id: 107,
        imgSrc: shot6,
    },
    {
        id: 108,
        imgSrc: shot7,
    },

]