import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import thedrs from "../assets/profile.png";
// import ShowMoreText from "react-show-more-text";
import { Link as LinkRouter } from "react-router-dom";

const Home = ({ resultRef }) => {
  // const [name, setName] = useState("");

  const handleClick = () => {
    resultRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
  };

  // const executeOnClick = (isExpanded) => {
  //   console.log(isExpanded);
  // };

  return (
    <div name="home" className="w-full h-screen bg-[#BBD6B8] background ">
      {/* Container */}

      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <figcaption class="mt-32 text-xl text-left text-gray-700 sm:text-4xl font-semibold pt-2">
          <div className="flex -ml-5">
            {" "}
            <img
              className=" h-[130px]  rounded-lg mt-[-30px] md:h-[200px]"
              src={thedrs}
              alt=""
            ></img>
          </div>
          Selamat Datang di BedahUmum.com
        </figcaption>
        <p className="text-gray-700 text-sm py-2 max-w-[800px] font-semibold text-justify sm:text-justify sm:text-base">
        BedahUmum.com adalah portal informasi bedah umum terbaik dan tercanggih yang didirikan oleh Dr. Tony Sukentro, SpB. 
        Kami berkomitmen menghadirkan edukasi seputar prosedur bedah minimal invasif, seperti laser wasir, laparoskopi, 
        dan hernia bilayer mesh, untuk memberikan solusi medis yang lebih aman, cepat, dan nyaman bagi pasien.
        </p>
        <p className="text-gray-700 text-sm py-2 max-w-[800px] font-semibold text-justify sm:text-justify sm:text-base">
        Sebagai bentuk kepedulian terhadap kesehatan masyarakat, kami juga menyediakan konsultasi online gratis bagi 
        pasien yang membutuhkan informasi atau pertolongan awal terkait masalah bedah, terutama untuk kondisi gawat darurat.
        </p>
        <p className="text-gray-700 text-sm py-2 max-w-[800px] font-semibold text-justify sm:text-justify sm:text-base">
        Dapatkan informasi akurat, terpercaya, dan inovatif hanya di BedahUmum.com!

          {/* <LinkRouter to="/intro" className="text-green-700">
            Lanjut Membaca
          </LinkRouter> */}
        </p>

        <div>
          <button
            onClick={handleClick}
            className="text-gray-600 bg-[#94AF9F] group border-2 px-6 py-3 my-2 flex items-center
           hover:bg-[#DBE4C6] hover:border-[#DBE4C6] hover:text-gray-900"
          >
            View Surgeon Speciality
            <span className="group-hover:rotate-90 duration-300">
              <HiArrowNarrowRight className="ml-3 " />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
