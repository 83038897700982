import React, { forwardRef, useState } from "react";
import icoedu from "../assets/icon-education.png";
import icocerti from "../assets/icon-certification.png";
import icoorganization from "../assets/icon-organization.png";
import { RxReset } from "react-icons/rx";

const Profile = forwardRef((props, ref) => {
  const [show, setShow] = useState(true);
  const [showtony, setShowtony] = useState(false);
  const [showherman, setShowherman] = useState(false);
  const [showmarolop, setShowmarolop] = useState(false);

  function showhide(index) {
    if (index === 0) {
      setShow(true);
      setShowtony(false);
      setShowherman(false);
      setShowmarolop(false);
    } else if (index === 1) {
      setShow(false);
      setShowtony(true);
      setShowherman(false);
      setShowmarolop(false);
    } else if (index === 2) {
      setShow(false);
      setShowtony(false);
      setShowherman(true);
      setShowmarolop(false);
    } else {
      setShow(false);
      setShowtony(false);
      setShowherman(false);
      setShowmarolop(true);
    }
  }
  return (
    <div
      ref={ref}
      name="profile"
      className="w-full h-screen bg-[#BBD6B8] text-gray-700"
    >
      <div className="flex flex-col justify-center items-center w-full h-full ">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pl-4 ">
            <p className="text-4xl font-bold inline border-b-4 border-green-600">
              Profile
            </p>
          </div>
          <div></div>
        </div>
        {/* <div>
          {show ? <h1>hello</h1> : null}
          <button onClick={() => setShow(!show)}>toggle</button>
        </div> */}
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4 ">
          {show ? (
            <div className="text-justify">
              <br></br>
              <p><b>
                Dr. Tony Sukentro, Sp.B
                </b>
              </p>
              <p></p>
              <p>
              Surgeon | Minimally Invasive Surgery Specialist{" "}
              </p>
              <br></br>
              <p>Dr. Tony Sukentro, Sp.B adalah seorang ahli bedah umum dengan keahlian dalam prosedur minimal invasif, termasuk laser wasir, laparoskopi, dan teknik bedah modern lainnya.</p>
              <br></br>
              <p> Dengan pengalaman lebih dari 15 tahun, beliau berkomitmen untuk memberikan perawatan bedah yang lebih aman, cepat, dan efektif bagi pasien.</p>  
              <br></br>
              <p>📍 Jakarta, Indonesia</p>
              <p>
              <a
              href="https://wa.me/628111733168?text=Hi%20dr%20Tony"
              target="_blank"
              rel="noreferrer"
             >  
                📞 +62 811-1733-168
              </a>              
              </p>
              <p>
              <a
              href="https://www.instagram.com/tonybedah27"
              target="_blank"
              rel="noreferrer"
             >📷 Instagram: @tonybedah27
              </a></p>
              <p><a
              href="https://www.youtube.com/tonysurgeon27"
              target="_blank"
              rel="noreferrer"
             >📹 YouTube: TonySurgeon27</a></p>{" "}
              <p></p>
              <br/>
             <p className="text-justify text-sm" >Untuk konsultasi atau informasi lebih lanjut, hubungi melalui kontak di atas.</p>
            </div>
            
          ) : null}
          {showtony ? (
            <div className="text-justify">
              <p>
                <b>Work Experience</b>{" "}
              </p>
              <p>
              <ul className="list-disc ml-5">
                <li>ST Wasir Center Clinic, Jakarta (2021 – Present)</li>
                <li>Harapan Bunda Hospital, Jakarta (2007 – Present)</li>
                <li>EMC Hospital, Jakarta (2010 – Present)</li>
                <li>Dharma Nugraha Hospital, Jakarta (2007 – 2017)</li>
              </ul>
              </p>
              <p></p>
              <br></br>
              <p>
                <b>Education History</b>{" "}
              </p>
              <p>
              <ul className="list-disc ml-5">
                <li>2007 – Specialist in Surgery, Faculty of Medicine, University of Indonesia, Jakarta</li>
                <li>2000 – Medical Doctor, Atma Jaya University, Jakarta</li>
              </ul>
              </p>
              <br></br>

              <div className="mx-auto flex flex-row items-baseline">
                <p>Kembali ke profile utama</p>
                <br />
                <RxReset onClick={() => showhide(0)} />
                <p></p>
              </div>
            </div>
          ) : null}
          {showherman ? (
            <div className="text-justify">
              <p>
                <b>Certifications & Specialized Training</b>{" "}
              </p>
              <p>
              <ul className="list-disc ml-5 text-sm">
                <li>2023 – Minimal Invasive Laser Therapies in Proctology, SUED Medical Centre, Ingolstadt, Germany</li>
                <li>2018 – Radiofrequency Haemorrhoidectomy Surgery Training, Intl St’ Marry Hospital, Korea</li>
                <li>2018 – LapSingle Surgery Training, Vives Namuh Hospital, Korea</li>
                <li>2016 – Hernia Repair Up-date, Gading Pluit Hospital, Jakarta, Indonesia</li>
                <li>2015 – Hemorrhoidopexy Clinical Immersion, Chulalongkorn Hospital, Thailand</li>
                <li>2012 – Basic Laparoscopic Surgery Course, Ho Chi Minh City, Vietnam</li>
                <li>2011 – Laparoscopic Hernia Surgery, Chulalongkorn Hospital, Thailand</li>
                <li>2011 – Hernia Repair with Bi-layer Mesh & Local Anesthesia Technique, Vajira Hospital, Thailand</li>
                <li>2006 – Advanced Laparoscopy, Pertamina Hospital, East Kalimantan, Indonesia</li>
              </ul>
              </p>
              <br></br>
              <div className="mx-auto flex flex-row items-baseline">
                <p>Kembali ke profile utama</p>
                <br />
                <RxReset onClick={() => showhide(0)} />
                <p></p>
              </div>
            </div>
          ) : null}
          {showmarolop ? (
            <div className="text-justify mt-10">
              <p>
                <b>External Positions & Affiliations</b>{" "}
              </p>
              <p>
              <ul className="list-disc ml-5 ">
                <li>2023 – Present – Public Communication, Indonesia Surgeons Association</li>
                <li>2022 – Present – Deputy Chairman of the Health Department, NasDem Party, North Jakarta</li>
                <li>2019 – Present – Committee Member, Indonesian Medical Tourism Association</li>
              </ul>
              </p>
              <p></p>
              <br></br>
              <div className="mx-auto flex flex-row items-baseline">
                <p>Kembali ke profile utama</p>
                <br />
                <RxReset onClick={() => showhide(0)} />
                <p></p>
              </div>
            </div>
          ) : null}
          <div className=" grid grid-cols-3 gap-2">
            <div className="items-center justify-center text-center ">
              <button>
                <img
                  className="shadow-[#94AF9F] hover:scale-110 duration-500 pt-2"
                  src={icoedu}
                  alt="drtonyprofile"
                  onClick={() => showhide(1)}
                />
              </button>
              <div className="text-xs my-2 bg-slate-100 w-full">
                <p className="text-xs my-2 ">Work Experience</p>
                <p className="text-xs my-2 "> & </p>
                <p className="text-xs my-2 ">Education History</p>
              </div>
            </div>
            <div className="items-center justify-center text-center">
              <button>
                <img
                  className="shadow-[#94AF9F] hover:scale-110 duration-500 pt-2"
                  src={icocerti}
                  alt="drhermanprofile"
                  onClick={() => showhide(2)}
                />
              </button>
              <div className="text-xs my-2 bg-slate-100 w-full">
                <p className="text-xs my-2 ">Certifications</p>
                <p className="text-xs my-2 "> & </p>
                <p className="text-xs my-2 ">Specialized Training</p>
              </div>
            </div>
            <div className="items-center justify-center text-center">
              <button>
                <img
                  className="shadow-[#94AF9F] hover:scale-110 duration-500 pt-2"
                  src={icoorganization}
                  alt="drmarlopprofile"
                  onClick={() => showhide(3)}
                />
              </button>
              <div className="text-xs my-2 bg-slate-100 w-full">
                <p className="text-xs my-2">External Positions</p>
                <p className="text-xs my-2"> & </p>
                <p className="text-xs my-2">Affiliations</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Profile;
